import React, { useEffect } from 'react';

const NotFoundPage = () => {
  useEffect(() => {
    // Check if the current URL matches the specified path
    if (window.location.pathname === '/luxurydiversityandinclusion/') {
      // Use the history object to navigate to the desired URL
      const targetPath = '/luxury-diversity-and-inclusion/';
      const fullPath = `${window.location.origin}${targetPath}`;
      window.history.replaceState(null, null, fullPath);
    }
  }, []);

  return (
    <div>
      <h1>Not Found</h1>
      <p>This page does not exist. You will be redirected shortly...</p>
      <p>Refresh after 10 seconds if not redirected</p>
    </div>
  );
};

export default NotFoundPage;
